/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import * as d3 from 'd3';
import _ from 'lodash';
import { Howl } from 'howler';
import {
  Button,
  Card,
  H5,
  Icon,
  Intent,
  Popover,
  PopoverInteractionKind,
  Position,
  Tooltip,
} from '@blueprintjs/core';
import { AnnotationBase, Task as ITask } from '@wavely/annotator-sdk';

import { findNode } from '../utils/tree';
import annotationsReducer, {
  addAnnotation,
  clearAnnotations,
  deleteAnnotation,
  initialState,
  updateAnnotation,
} from '../store/annotations';
import useAudioObjectURL from '../effects/useAudioObjectURL';

import LabelsForm from './LabelsForm';
import BrushLayer from './BrushLayer';
import Progression from './Progression';
import PlayerContainer from './PlayerContainer';
import ReplayContainer from './ReplayContainer';

import './Annotations.scss';

interface TaskProps {
  task: ITask;
  onAnnotationsCompletion: (annotation: Array<AnnotationBase>) => void;
}

const Annotations = (props: TaskProps) => {
  const { task, onAnnotationsCompletion } = props;

  const soundsRef = React.useRef<Howl>();
  const scaleRef = React.useRef<d3.ScaleLinear<number, number>>();

  const frequencyAxisRef = React.useRef<SVGGElement>(null);
  const [playbacksMap, setPlaybacksMap] = React.useState<{
    [annotationId: string]: number;
  }>({});

  const [partialAnnotation, setPartialAnnotation] = React.useState<
    Partial<AnnotationBase | null>
  >(null);

  const [annotations, dispatch] = React.useReducer(
    annotationsReducer,
    initialState,
  );

  const audioObjectUrl = useAudioObjectURL(task.sample);

  React.useEffect(() => {
    if (!audioObjectUrl) {
      return undefined;
    }

    soundsRef.current = new Howl({
      src: audioObjectUrl,
      sprite: _.mapValues(
        annotations.byId,
        a => [a.onset, a.offset - a.onset] as [number, number],
      ),
      format: ['mp3'],
    });

    return () => {
      if (soundsRef.current) {
        soundsRef.current.unload();
      }
    };
  }, [soundsRef, audioObjectUrl, annotations.byId]);

  const submitLabels = React.useCallback(
    (labels: Array<number>) => {
      dispatch(
        addAnnotation({ ...partialAnnotation, labels } as AnnotationBase),
      );
      setPartialAnnotation(null);
    },
    [partialAnnotation, setPartialAnnotation, dispatch],
  );

  React.useEffect(() => {
    if (frequencyAxisRef.current) {
      const frequencyScale = d3
        .scaleLinear()
        .domain([task.metadata.inputSettings!.sampleRate! / 2, 0])
        .range([0, 200]);

      const axis = d3.axisLeft(frequencyScale).ticks(5, 's');
      d3.select(frequencyAxisRef.current).call(axis);
    }
  }, [task, frequencyAxisRef]);

  const setScale = React.useCallback((duration: number) => {
    scaleRef.current = d3
      .scaleLinear()
      .domain([0, duration])
      .range([0, 1200]);
  }, []);

  const cancelAnnotation = React.useCallback(() => setPartialAnnotation(null), [
    setPartialAnnotation,
  ]);

  const initAnnotation = React.useCallback(
    ([xStart, xEnd]: [number, number]) => {
      if (!scaleRef.current) {
        return;
      }
      const onset = Math.trunc(scaleRef.current.invert(xStart) * 1000);
      const offset = Math.trunc(scaleRef.current.invert(xEnd) * 1000);
      setPartialAnnotation({ onset, offset });
    },
    [setPartialAnnotation],
  );

  const getSampleDomain = () => {
    if (!scaleRef.current) {
      return undefined;
    }
    const domain = scaleRef.current.domain();
    return { onset: 0, offset: Math.trunc(domain[1] * 1000) };
  };

  const resizeAnnotation = React.useCallback(() => {
    setPartialAnnotation(annotation => ({
      ...annotation,
      ...getSampleDomain(),
    }));
  }, [setPartialAnnotation]);

  const renderAnnotationMarker = (
    [id, annotation]: [string, AnnotationBase],
    index: number,
  ) => {
    const { onset, offset } = annotation;

    if (!scaleRef.current) {
      return null;
    }

    const x0 = scaleRef.current(annotation.onset / 1000);
    const x1 = scaleRef.current(annotation.offset / 1000);

    const playAnnotation = () => {
      const sounds = soundsRef.current;
      let playId = playbacksMap[id];

      if (!sounds) {
        return;
      }

      if (playId) {
        sounds.seek(onset / 1000, playId);
      } else {
        playId = sounds.play(id);
        setPlaybacksMap(m => ({ ...m, [id]: playId }));
        sounds.once('end', () => setPlaybacksMap(m => _.omit(m, id)), playId);
      }
    };

    const getProgression = () => {
      const sounds = soundsRef.current;
      const playId = playbacksMap[id];

      if (!sounds || !playId) {
        return 0;
      }

      const duration = offset - onset; // ms
      const absoluteSeek = sounds.seek(undefined, playId) as number; // seconds
      const relativeSeek = 1000 * absoluteSeek - onset; // ms

      return relativeSeek / duration; // [0,1]
    };

    return (
      <div
        key={annotation.offset}
        className="annotation-marker"
        onClick={playAnnotation}
        onMouseDown={event => event.stopPropagation()}
        style={{
          left: `${x0}px`,
          width: `${x1 - x0}px`,
          top: `${index * 10}%`,
        }}
      >
        <Progression getProgression={getProgression} />
        <div className="popover-wrapper">
          <Popover
            interactionKind={PopoverInteractionKind.HOVER}
            content={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Card onClick={event => event.stopPropagation()}>
                <LabelsForm
                  // eslint-disable-next-line react/prop-types
                  labels={task.labels}
                  initialLabels={annotation.labels}
                  onResize={() =>
                    dispatch(
                      updateAnnotation(id, {
                        ...annotation,
                        ...getSampleDomain(),
                      }),
                    )
                  }
                  onSubmit={labels =>
                    dispatch(updateAnnotation(id, { ...annotation, labels }))
                  }
                  onCancel={() => dispatch(deleteAnnotation(id))}
                />
              </Card>
            }
            target={<div />}
          />
        </div>
        <Icon icon="play" />
        <span className="annotation-label">
          {annotation.labels
            // eslint-disable-next-line react/prop-types
            .map(l => findNode(task.labels[0] as any, l)!.name)
            .join(', ')}
        </span>
      </div>
    );
  };

  return (
    <>
      <Card className="annotations-container">
        <H5 className="title-header">
          <Tooltip
            position={Position.RIGHT}
            content="Start by brushing an area on the spectogram"
          >
            <div className="title">
              <span>Annotation Bench</span>
              <Icon icon="help" iconSize={12} />
            </div>
          </Tooltip>
        </H5>
        <svg className="left-axis">
          <g
            ref={frequencyAxisRef}
            style={{ transform: 'translate(30px, 0)' }}
          />
        </svg>
        <BrushLayer onBrushEnd={initAnnotation}>
          {area => (
            <>
              <div className="annotations">
                {Object.entries(annotations.byId).map(renderAnnotationMarker)}
                {area && (
                  <div
                    className="current-annotation"
                    style={{
                      width: `${area[1] - area[0]}px`,
                      left: `${area[0]}px`,
                    }}
                  />
                )}
                {partialAnnotation && scaleRef.current && (
                  <div
                    className="current-annotation"
                    style={{
                      width: `${scaleRef.current(
                        (partialAnnotation.offset! - partialAnnotation.onset!) /
                          1000,
                      )}px`,
                      left: `${scaleRef.current(
                        partialAnnotation.onset! / 1000,
                      )}px`,
                    }}
                  >
                    {audioObjectUrl && (
                      <ReplayContainer
                        audioUrl={audioObjectUrl}
                        onset={partialAnnotation.onset!}
                        offset={partialAnnotation.offset!}
                      />
                    )}
                    <div className="popover-wrapper">
                      <Popover
                        interactionKind={PopoverInteractionKind.HOVER}
                        isOpen
                        content={
                          // eslint-disable-next-line react/jsx-wrap-multilines
                          <Card onMouseDown={event => event.stopPropagation()}>
                            <LabelsForm
                              labels={task.labels}
                              initialLabels={partialAnnotation.labels}
                              onResize={resizeAnnotation}
                              onSubmit={submitLabels}
                              onCancel={cancelAnnotation}
                            />
                          </Card>
                        }
                        target={<div />}
                      />
                    </div>
                  </div>
                )}
              </div>
              <img
                className="annotation-medium"
                src={task.spectrogram}
                alt="spectrogram"
              />
            </>
          )}
        </BrushLayer>
        {audioObjectUrl && (
          <PlayerContainer
            width={1200}
            audioUrl={audioObjectUrl}
            onReady={setScale}
          />
        )}
      </Card>
      <Card className="task-controls">
        <Button
          type="button"
          intent={Intent.NONE}
          icon="reset"
          text="Reset Task"
          onClick={() => dispatch(clearAnnotations())}
        />
        <Button
          type="submit"
          intent={Intent.PRIMARY}
          icon="tick"
          text="Finish Task"
          onClick={() =>
            onAnnotationsCompletion(Object.values(annotations.byId))
          }
        />
      </Card>
    </>
  );
};

export default Annotations;
