import React from 'react';
import { Howl } from 'howler';

import Progression from './Progression';

export interface ProgressProps {
  audioUrl: string;
  onset: number; // ms
  offset: number; // ms
}

const ReplayContainer = (props: ProgressProps) => {
  const [id, setId] = React.useState<number | null>(null);

  const soundsRef = React.useRef<Howl>();

  const { audioUrl, onset, offset } = props;

  const getProgression = React.useCallback(() => {
    const sounds = soundsRef.current;

    if (!sounds || !id) {
      return 0;
    }

    const duration = offset - onset; // ms
    const absoluteSeek = sounds.seek(undefined, id) as number; // seconds
    const relativeSeek = 1000 * absoluteSeek - onset; // ms

    return relativeSeek / duration; // [0,1]
  }, [id, onset, offset]);

  React.useEffect(() => {
    const sounds = new Howl({
      src: [audioUrl],
      sprite: {
        current: [onset, offset - onset],
      },
      loop: true,
      format: ['mp3'],
    });

    soundsRef.current = sounds;
    setTimeout(() => setId(sounds.play('current')), 0);

    return () => {
      setId(null);
      sounds.unload();
    };
  }, [soundsRef, audioUrl, onset, offset, setId]);

  return <Progression getProgression={getProgression} />;
};

export default ReplayContainer;
