import _ from 'lodash';
import { AnnotationBase } from '@wavely/annotator-sdk';

export const ADD_ANNOTATION = 'ADD_ANNOTATION';
export interface AddAnnotation {
  type: typeof ADD_ANNOTATION;
  item: AnnotationBase;
}

export const UPDATE_ANNOTATION = 'UPDATE_ANNOTATION';
export interface UpdateAnnotation {
  type: typeof UPDATE_ANNOTATION;
  id: string;
  item: AnnotationBase;
}

export const DELETE_ANNOTATION = 'DELETE_ANNOTATION';
export interface DeleteAnnotation {
  type: typeof DELETE_ANNOTATION;
  id: string;
}

export const CLEAR_ANNOTATIONS = 'CLEAR_ANNOTATIONS';
export interface ClearAnnotations {
  type: typeof CLEAR_ANNOTATIONS;
}

export type AnnotationActions =
  | AddAnnotation
  | UpdateAnnotation
  | DeleteAnnotation
  | ClearAnnotations;

export const addAnnotation = (annotation: AnnotationBase): AddAnnotation => ({
  type: ADD_ANNOTATION,
  item: annotation,
});

export const updateAnnotation = (
  id: string,
  annotation: AnnotationBase,
): UpdateAnnotation => ({
  type: UPDATE_ANNOTATION,
  id,
  item: annotation,
});

export const deleteAnnotation = (id: string): DeleteAnnotation => ({
  type: DELETE_ANNOTATION,
  id,
});

export const clearAnnotations = (): ClearAnnotations => ({
  type: CLEAR_ANNOTATIONS,
});

interface AnnotationsState {
  allIds: Array<string>;
  byId: { [id: string]: AnnotationBase };
}

export const initialState: AnnotationsState = {
  allIds: [],
  byId: {},
};

const annotationsReducer = (
  state: AnnotationsState = initialState,
  action: AnnotationActions,
): AnnotationsState => {
  switch (action.type) {
    case ADD_ANNOTATION: {
      const id = new Date().getTime().toString();
      return {
        allIds: [...state.allIds, id],
        byId: { ...state.byId, [id]: action.item },
      };
    }
    case UPDATE_ANNOTATION:
      return {
        ...state,
        byId: { ...state.byId, [action.id]: action.item },
      };
    case DELETE_ANNOTATION:
      return {
        allIds: state.allIds.filter(id => id !== action.id),
        byId: _.omit(state.byId, action.id),
      };
    case CLEAR_ANNOTATIONS:
      return initialState;
    default:
      return state;
  }
};

export default annotationsReducer;
