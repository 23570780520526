export interface Identified {
  id: number;
}

export type TreeNode<T> = {
  children: Array<TreeNode<T>>;
} & T;

export const findNode = <T extends Identified>(
  rootNode: TreeNode<T>,
  id: number,
): TreeNode<T> | null => {
  if (rootNode.id === id) {
    return rootNode;
  }
  if (rootNode.children.length === 0) {
    return null;
  }
  for (let i = 0; i < rootNode.children.length; i += 1) {
    const node = findNode(rootNode.children[i], id);
    if (node) {
      return node;
    }
  }
  return null;
};

export const stratifyTree = <T>(
  rootNode: TreeNode<T>,
): Array<Array<TreeNode<T>>> => {
  const nodesByLevel = [[rootNode]];
  for (let i = 0; nodesByLevel[i].length > 0; i += 1) {
    nodesByLevel[i + 1] = nodesByLevel[i].reduce<Array<TreeNode<T>>>(
      (acc, n) => [...acc, ...n.children],
      [],
    );
  }
  return nodesByLevel.slice(0, -1);
};
