import React from 'react';
import moment from 'moment';
import { Card, H5, Icon, Position, Text, Tooltip } from '@blueprintjs/core';
import { Metadata } from '@wavely/annotator-sdk';

import './TaskMetadata.scss';

export interface TaskMetadataProps {
  metadata: Metadata;
}

const TaskMetadata = (props: TaskMetadataProps) => {
  const { metadata } = props;
  const recordingMoment = moment(metadata.startDate);

  return (
    <Card>
      <H5>Metadata</H5>
      <div className="task-metadata">
        <Tooltip content="Project" position={Position.RIGHT}>
          <div className="task-metadatum">
            <Icon icon="projects" />
            <Text>{metadata.campaign || 'Unknown'}</Text>
          </div>
        </Tooltip>
        <Tooltip content="Recording time" position={Position.RIGHT}>
          <div className="task-metadatum">
            <Icon icon="time" />
            <Text>
              {metadata.startDate && recordingMoment.isValid()
                ? recordingMoment.format('LT')
                : 'Unknown'}
            </Text>
          </div>
        </Tooltip>
      </div>
    </Card>
  );
};

export default TaskMetadata;
