import {
  DefaultApi as AnnotatorApi,
  Configuration,
  Credentials,
} from '@wavely/annotator-sdk';

import axios from './axios';

class AnnotatorApiClient extends AnnotatorApi {
  public login(credentials: Credentials) {
    return this.obtainTokenPair(credentials).then(res => {
      this.configuration = new Configuration({
        username: credentials.username,
        accessToken: res.data.access,
      });

      return res;
    });
  }
}

const annotatorApiClient = new AnnotatorApiClient(undefined, 'https://annotator.wavely.fr', axios);

export default annotatorApiClient;
