import _ from 'lodash';
import axios from 'axios';

// Sets CRSF token for Django
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

// TODO(vmatt): put this in utils
const changeObjectCase = (
  o: any,
  caseTransformer: (s: string) => string,
): any => {
  if (_.isArray(o)) {
    return o.map(i => changeObjectCase(i, caseTransformer));
  }
  if (_.isObject(o)) {
    return Object.entries(o).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [caseTransformer(key)]:
          value instanceof Object
            ? changeObjectCase(value, caseTransformer)
            : value,
      }),
      {},
    );
  }
  return o;
};

// Snake case request object
axios.defaults.transformRequest = data => {
  if (data === undefined) {
    // Nothing to do.
    return data;
  }
  return JSON.stringify(changeObjectCase(JSON.parse(data), _.snakeCase));
};

// Camel case response object
axios.defaults.transformResponse = (data, headers) => {
  if (headers['content-type'] === 'application/json') {
    return changeObjectCase(JSON.parse(data), _.camelCase);
  }
  return data;
};

export default axios;
