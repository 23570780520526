import React from 'react';
import { Score } from '@wavely/annotator-sdk';
import { Card, H5, HTMLTable, Icon, Intent, Spinner } from '@blueprintjs/core';

import annotatorApiClient from '../utils/api';
import useAuth from '../effects/useAuth';

import './Leaderboard.scss';

const Leaderboard = () => {
  const { username } = useAuth();
  const [scores, setScores] = React.useState<Array<Score>>([]);
  const [isLoading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    annotatorApiClient
      .listScores()
      .then(res => {
        setScores(res.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [setScores, setLoading]);

  if (isLoading) {
    return <Spinner intent={Intent.PRIMARY} size={25} />;
  }

  return (
    <Card className="leaderboard-container">
      <H5>Leaderboard</H5>
      <HTMLTable condensed striped>
        <thead>
          <tr>
            <td />
            <th>Rank</th>
            <th>Username</th>
            <th>Tasks completed</th>
          </tr>
        </thead>
        <tbody>
          {scores.map((score, index) => (
            <tr
              key={score.id}
              className={username === score.username ? `user-score` : ''}
            >
              <td>
                {username === score.username && (
                  <Icon intent={Intent.PRIMARY} icon="user" />
                )}
              </td>
              <td>{index + 1}</td>
              <td>{score.username}</td>
              <td>{score.tasksCount}</td>
            </tr>
          ))}
        </tbody>
      </HTMLTable>
    </Card>
  );
};

export default Leaderboard;
