import React from 'react';
import { Credentials } from '@wavely/annotator-sdk';

import annotatorApiClient from '../utils/api';
import {
  AuthContext,
  error,
  login as loginDispatch,
} from '../components/AuthProvider';

const useAuth = () => {
  const [state, dispatch] = React.useContext(AuthContext);

  const login = (credentials: Credentials) =>
    annotatorApiClient
      .login(credentials)
      .then(res => {
        dispatch(
          // TODO(vmatt): remove after SDK update
          loginDispatch({ username: credentials.username, ...res.data }),
        );
      })
      .catch(err => {
        if (err.response) {
          // The server responded with a status code outside the range 2xx
          dispatch(
            error({
              error: err.response.data.detail,
              status: err.response.status,
            }),
          );
        }
      });

  return {
    isAuthenticated: state.isAuthenticated,
    username: state.username,
    error: state.error,
    login,
    status: state.status,
  };
};

export default useAuth;
