import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import './App.scss';

import Annotator from './pages/Annotator';
import Leaderboard from './pages/Leaderboard';
import Auth from './pages/Auth';
import useAuth from './effects/useAuth';

const App: React.FC = () => {
  const { isAuthenticated } = useAuth();

  return (
    <main className="app">
      <Switch>
        <Route
          exact
          path="/"
          render={() =>
            isAuthenticated ? <Annotator /> : <Redirect to="/auth" />
          }
        />
        <Route
          exact
          path="/leaderboard"
          render={() =>
            isAuthenticated ? <Leaderboard /> : <Redirect to="/auth" />
          }
        />
        <Route exact path="/auth" render={() => <Auth />} />
      </Switch>
    </main>
  );
};

export default App;
