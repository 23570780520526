/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import {
  Button,
  ButtonGroup,
  Intent,
  Position,
  Tooltip,
} from '@blueprintjs/core';
import { Field, FieldProps, Form, Formik, FormikActions } from 'formik';
import { Label } from '@wavely/annotator-sdk';

import { TreeNode } from '../../utils/tree';
import Columns, { RenderLevelInputs } from '../Columns';

import './LabelsForm.scss';

interface AnnotationFormProps {
  labels: Array<Label>;
  initialLabels?: Array<number>;
  onResize: () => void;
  onSubmit: (labels: Array<number>) => void;
  onCancel: () => void;
}

interface FormValues {
  labels: Array<number>;
}

const CheckBox = ({
  field,
  form,
  label,
}: FieldProps & { label: TreeNode<Label> }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      form.setFieldValue('labels', field.value.concat(label.id));
    } else {
      let labels: Array<number> = field.value;
      const removeLabel = (currentLabel: TreeNode<Label>) => {
        currentLabel.children.forEach(l => removeLabel(l));
        labels = labels.filter(v => v !== label.id);
      };
      removeLabel(label);
      form.setFieldValue('labels', labels);
    }
  };

  return (
    <label className="bp3-control bp3-checkbox">
      <input
        type="checkbox"
        checked={field.value.includes(label.id)}
        onChange={handleChange}
      />
      <span className="bp3-control-indicator" />
      {label.name}
    </label>
  );
};

const validate = (values: FormValues) =>
  values.labels.length > 0 ? {} : { labels: 'Select a label' };

/**
 * Component to select a subset from a label set.
 *
 * @param props
 */
const LabelsForm = (props: AnnotationFormProps) => {
  const { initialLabels, onResize, onCancel, onSubmit, labels } = props;

  const initialValues: FormValues = {
    labels: initialLabels || [],
  };

  const submit = React.useCallback(
    (values: FormValues, actions: FormikActions<FormValues>) => {
      onSubmit(values.labels);
      actions.setSubmitting(false);
    },
    [onSubmit],
  );

  const renderLevel = React.useCallback(
    (
      selectedValues: Array<number>,
      { level, stratifiedTree }: RenderLevelInputs,
    ): React.ReactNode => {
      // Ignore root node
      if (level === 0) {
        return undefined;
      }

      const selectedParentNodes =
        level >= 2
          ? stratifiedTree[level - 1].filter(n =>
              selectedValues.includes(n.id!),
            )
          : stratifiedTree[0];

      const renderGroup = (nodesGroup: Array<TreeNode<Label>>) => {
        const renderNode = (node: TreeNode<Label>) => {
          return <Field component={CheckBox} name="labels" label={node} />;
        };

        if (nodesGroup.length === 0) {
          return undefined;
        }

        return (
          <div>
            {nodesGroup.map(n => (
              <React.Fragment key={n.id}>{renderNode(n)}</React.Fragment>
            ))}
          </div>
        );
      };

      return (
        <>
          {selectedParentNodes.map(p => (
            <React.Fragment key={p.id}>
              {renderGroup(p.children)}
            </React.Fragment>
          ))}
        </>
      );
    },
    [],
  );

  return (
    <Formik
      onSubmit={submit}
      initialValues={initialValues}
      validate={validate}
      render={({ values, isSubmitting }) => (
        <Form className="task-form">
          <Columns
            tree={labels[0] as TreeNode<Label>}
            renderLevel={inputs => renderLevel(values.labels, inputs)}
          />
          <ButtonGroup>
            <Tooltip
              content="Pick full sample domain"
              openOnTargetFocus={false}
              position={Position.LEFT}
            >
              <Button
                name="resize"
                icon="arrows-horizontal"
                onClick={onResize}
              />
            </Tooltip>
            <Button
              name="submit"
              type="submit"
              intent={Intent.PRIMARY}
              disabled={isSubmitting}
              icon="tick"
              text="Submit Labels"
            />
            <Button
              name="remove"
              type="button"
              intent={props.initialLabels ? Intent.DANGER : Intent.NONE}
              icon={props.initialLabels ? 'trash' : 'cross'}
              onClick={onCancel}
            />
          </ButtonGroup>
        </Form>
      )}
    />
  );
};

export default LabelsForm;
