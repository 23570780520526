import React from 'react';
import { Redirect } from 'react-router-dom';
import { Field, FieldProps, Form, Formik, FormikActions } from 'formik';
import {
  Button,
  Callout,
  FormGroup,
  InputGroup,
  Intent,
} from '@blueprintjs/core';

import './Auth.scss';

import useAuth from '../effects/useAuth';

// @ts-ignore
import logo from './logo.svg';

interface FormValues {
  username: string;
  password: string;
}

const validate = (values: FormValues) => {
  let errors = {};

  if (values.username === '') {
    errors = { ...errors, username: 'Please enter an username' };
  }
  if (values.password === '') {
    errors = { ...errors, password: 'Please enter a password' };
  }

  return errors;
};

const Auth = () => {
  const initialValues: FormValues = {
    username: '',
    password: '',
  };

  const { isAuthenticated, username, login, error } = useAuth();

  const onSubmit = (values: FormValues, actions: FormikActions<FormValues>) => {
    login(values).finally(() => actions.setSubmitting(false));
  };

  if (isAuthenticated) {
    return (
      <div className="box">
        Logged in as <span className="has-text-weight-bold">{username}</span>
        <Redirect to="/" />
      </div>
    );
  }

  return (
    <div className="login-container">
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validate}
        render={({ isSubmitting, errors }) => (
          <Form className="login-form">
            <FormGroup
              label="Username"
              labelFor="username-input"
              labelInfo="(required)"
              helperText={errors.username}
            >
              <Field
                id="username-input"
                name="username"
                autoFocus
                render={({ field }: FieldProps<FormValues>) => (
                  <InputGroup
                    type="text"
                    placeholder="Enter your username..."
                    leftIcon="person"
                    intent={errors.username ? Intent.DANGER : undefined}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                  />
                )}
              />
            </FormGroup>
            <FormGroup
              label="Password"
              labelFor="password-input"
              labelInfo="(required)"
              helperText={errors.password}
            >
              <Field
                name="password"
                render={({ field }: FieldProps<FormValues>) => (
                  <InputGroup
                    type="password"
                    placeholder="Enter your password..."
                    leftIcon="key"
                    intent={errors.password ? Intent.DANGER : undefined}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                  />
                )}
              />
            </FormGroup>

            <Button
              type="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
              intent={error ? Intent.DANGER : Intent.PRIMARY}
              icon="log-in"
            >
              Sign in
            </Button>
            {error && <Callout intent={Intent.DANGER}>{error}</Callout>}
          </Form>
        )}
      />
      <img src={logo} alt="Logo" />
    </div>
  );
};

export default Auth;
