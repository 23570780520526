import React from 'react';

import './Progression.scss';

export interface ProgressionProps {
  getProgression: () => number; // [0,1]
}

const Progression = (props: ProgressionProps) => {
  const progressRef = React.useRef<HTMLDivElement>(null);

  const { getProgression } = props;

  React.useEffect(() => {
    let requestId: number;

    const step = () => {
      if (progressRef.current) {
        progressRef.current.style.width = `${100 * getProgression()}%`;
      }
      requestId = window.requestAnimationFrame(step);
    };
    requestId = window.requestAnimationFrame(step);

    return () => window.cancelAnimationFrame(requestId);
  }, [getProgression]);

  return <div ref={progressRef} className="progression-current" />;
};

export default Progression;
