import React from 'react';
import { Button, Callout, Card, H4, OL, Tag } from '@blueprintjs/core';
import { Intent } from '@blueprintjs/core/lib/esm/common/intent';

import './Rules.scss';

const Rules = () => {
  return (
    <Card className="rules">
      <H4>
        Bienvenue sur <b>Annotator</b> !
      </H4>
      <p>Vous allez nous aider à annotater des enregistrements sonores.</p>
      <p>
        Pour ce faire, nous allons vous proposer une succession
        d&apos;enregistrements (avec leur spectrogramme), et vous allez réaliser
        une tâche pour chacun de ces enregistrements.
      </p>
      <p>
        Ainsi, pour un enregistrement donné, il vous faudra préciser le début et
        la fin de chaque événement sonore entendu, et la source probable de
        chaque événement.
      </p>
      <p>
        <b>Voici nos consignes:</b>
      </p>
      <OL>
        <li>
          <p>
            Cliquez sur <Button icon="play" minimal large /> et écoutez
            l&apos;enregistrement sonore.
          </p>
        </li>
        <li>
          <p>
            Pour chaque événement sonore que vous avez perçue dans
            l&apos;enregistrement,
          </p>
          <OL type="A">
            <li>
              Dessinez sur le spectrogramme la période correspondant à cet
              événement. Vous créez ainsi une annotation.
            </li>
            <li>
              Sélectionnez, dans la liste qui s&apos;affiche, la source qui,
              selon vous, est à l&apos;origine de cet événement.
            </li>
            <li>
              Si vous hésitez entre plusieurs sources pour cet événement,
              sélectionnez plusieurs sources.
            </li>
            <li>
              Si vous avez clairement identifié la source de cet événement et
              que cette source n&apos;est pas listée, sélectionnez la catégorie{' '}
              <Tag minimal>Autre</Tag> puis <Tag minimal>Non listé</Tag>
            </li>
            <li>
              Si vous ne parvenez pas à identifier une ou plusieurs sources
              potentielles, sélectionnez <Tag minimal>Ne sait pas</Tag>
            </li>
          </OL>
          <p>
            Répétez, pour chaque événement perçu, les opérations A, B, C, D et
            E.
          </p>
          <Callout className="help-callout" icon="info-sign">
            Dans le cas de sources répétitives (coups de marteau, BRH, ...),
            créez une seule annotation, en sélectionnant la plus petite zone
            contenant toutes les occurrences.
          </Callout>
          <Callout className="help-callout" icon="info-sign">
            Vous pouvez réinitialiser la tâche en cours en cliquant sur{' '}
            <Button icon="reset">Reset Task</Button>
          </Callout>
        </li>
        <li>
          <p>
            Si vous doutez de manière trop importante, passez à
            l&apos;enregistrement suivant en cliquant sur{' '}
            <Button icon="chevron-forward">Skip Task</Button>
          </p>
        </li>
        <li>
          <p>
            Une fois que tous les événements sonores ont été annotés, cliquez
            sur
            <Button intent={Intent.PRIMARY} icon="tick">
              Finish Task
            </Button>{' '}
            pour terminer la tâche.
          </p>
        </li>
      </OL>
    </Card>
  );
};

export default Rules;
