/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import * as d3 from 'd3';
import {
  Button,
  Card,
  Divider,
  Position,
  Tooltip,
  Elevation,
} from '@blueprintjs/core';
import { Howl } from 'howler';

import './PlayerContainer.scss';

export interface AudioPlayerProps {
  audioUrl: string;
  onReady: (duration: number) => void;
  width: number;
}

const PlayerContainer = (props: AudioPlayerProps) => {
  const [isPlaying, setPlaying] = React.useState<boolean>(false);
  const [isLooping, setLooping] = React.useState<boolean>(false);

  const soundRef = React.useRef<Howl>();
  const scaleRef = React.useRef<d3.ScaleLinear<number, number>>();
  const progressRef = React.useRef<HTMLDivElement>(null);
  const timeAxisRef = React.useRef<SVGSVGElement>(null);

  const { audioUrl, onReady, width } = props;

  const setScaleAndAxis = React.useCallback(
    (duration: number) => {
      if (timeAxisRef.current) {
        scaleRef.current = d3
          .scaleLinear()
          .domain([0, duration])
          .range([0, width]);

        const axis = d3.axisBottom(scaleRef.current).ticks(8, 's');
        d3.select(timeAxisRef.current).call(axis);
      }
    },
    [width],
  );

  React.useEffect(() => {
    const sound = new Howl({
      src: [audioUrl],
      format: ['mp3'],
    });

    sound.once('load', () => {
      const duration = sound.duration();
      setScaleAndAxis(duration);
      onReady(duration);
    });

    sound.on('play', () => setPlaying(true));
    sound.on('end', () => setPlaying(false));
    sound.on('pause', () => setPlaying(false));
    sound.on('stop', () => setPlaying(false));

    soundRef.current = sound;

    return () => sound.unload();
  }, [soundRef, audioUrl, onReady, setScaleAndAxis]);

  React.useEffect(() => {
    let requestId: number;
    const step = () => {
      if (soundRef.current && progressRef.current) {
        const seek = soundRef.current.seek() as number;
        progressRef.current.style.width = `${(100 * seek) /
          soundRef.current.duration()}%`;
      }
      requestId = window.requestAnimationFrame(step);
    };
    requestId = window.requestAnimationFrame(step);
    return () => window.cancelAnimationFrame(requestId);
  }, [soundRef, progressRef]);

  const play = React.useCallback(() => {
    if (soundRef.current && !soundRef.current.playing()) {
      soundRef.current.play();
    }
  }, [soundRef]);
  const pause = React.useCallback(() => {
    if (soundRef.current) {
      soundRef.current.pause();
    }
  }, [soundRef]);
  const toggleLoop = React.useCallback(() => {
    if (soundRef.current) {
      if (isLooping) {
        soundRef.current.loop(false);
        soundRef.current.on('end', () => setPlaying(false));
        setLooping(false);
      } else {
        soundRef.current.loop(true);
        soundRef.current.off('end');
        setLooping(true);
      }
    }
  }, [soundRef, setLooping, isLooping]);
  const seek = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (soundRef.current && scaleRef.current) {
        const rect = event.currentTarget.getBoundingClientRect();
        const x = event.clientX - rect.left - event.currentTarget.clientLeft;
        const sec = scaleRef.current.invert(x);
        soundRef.current.seek(sec);
      }
    },
    [soundRef, scaleRef],
  );

  return (
    <div className="player-container">
      <div className="strip" onClick={seek}>
        <div className="bar">
          <div ref={progressRef} className="progress" />
        </div>
        <svg ref={timeAxisRef} className="time-axis" />
      </div>
      <div className="controls">
        <Card className="button-group" elevation={Elevation.TWO}>
          <Tooltip
            content={isPlaying ? `Pause` : `Play sample`}
            position={Position.LEFT}
            hoverOpenDelay={250}
          >
            {!isPlaying ? (
              <Button
                className="is-highlight"
                type="button"
                onClick={play}
                icon="play"
                minimal
                large
              />
            ) : (
              <Button
                className="is-highlight"
                type="button"
                onClick={pause}
                icon="pause"
                minimal
                large
              />
            )}
          </Tooltip>
          <Divider />
          <Tooltip
            content="Repeat sample"
            position={Position.RIGHT}
            hoverOpenDelay={250}
          >
            <Button
              type="button"
              onClick={toggleLoop}
              icon="repeat"
              active={isLooping}
              minimal
            />
          </Tooltip>
        </Card>
      </div>
    </div>
  );
};

export default PlayerContainer;
