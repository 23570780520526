/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

type Area = [number, number];

export interface BrushLayerProps {
  children: (area: Area | null) => React.ReactElement | null;
  onBrushEnd: (area: Area) => void;
}

const getX = (event: React.MouseEvent<HTMLDivElement>) => {
  const rect = event.currentTarget.getBoundingClientRect();
  const x = event.clientX - rect.left - event.currentTarget.clientLeft;
  return Math.max(Math.min(x, rect.width), 0);
};

/**
 * Component encapsulating brushing.
 *
 * @param props
 */
const BrushLayer = (props: BrushLayerProps) => {
  const [isBrushing, setIsBrushing] = React.useState<boolean>(false);
  const [area, setArea] = React.useState<Area | null>(null);

  const { children, onBrushEnd } = props;

  const startBrush = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      const xStart = getX(event);
      setArea([xStart, xStart]);
      setIsBrushing(true);
    },
    [setArea],
  );

  const brush = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (!isBrushing || !area) {
        return;
      }
      const [xStart] = area;
      const xEnd = getX(event);
      setArea([xStart, xEnd]);
    },
    [isBrushing, area, setArea],
  );

  const endBrush = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (!isBrushing || !area) {
        return;
      }
      const [xStart] = area;
      const xEnd = getX(event);
      setIsBrushing(false);
      setArea(null);
      onBrushEnd(xStart <= xEnd ? [xStart, xEnd] : [xEnd, xStart]);
    },
    [isBrushing, area, setIsBrushing, setArea, onBrushEnd],
  );

  return (
    <div
      className="annotation-layer"
      onMouseDown={startBrush}
      onMouseMove={brush}
      onMouseUp={endBrush}
      onMouseLeave={endBrush}
    >
      {children(area && (area[0] <= area[1] ? area : [area[1], area[0]]))}
    </div>
  );
};

export default BrushLayer;
