import React from 'react';
import { Label } from '@wavely/annotator-sdk';

import { stratifyTree, TreeNode } from '../../utils/tree';

import './Columns.scss';

export interface RenderLevelInputs {
  nodes: Array<TreeNode<Label>>;
  level: number;
  stratifiedTree: Array<Array<TreeNode<Label>>>;
}

interface ColumnsProps {
  tree: TreeNode<Label>;
  renderLevel: (props: RenderLevelInputs) => React.ReactNode;
  renderColumnDivider?: () => React.ReactNode;
}

const Columns = (props: ColumnsProps) => {
  const { tree, renderLevel } = props;
  const stratifiedTree = stratifyTree(tree);

  return (
    <div className="miller-columns">
      {stratifiedTree.map((levelNodes, level) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={level} className="miller-column">
          {renderLevel({ nodes: levelNodes, level, stratifiedTree })}
        </div>
      ))}
    </div>
  );
};

export default Columns;
