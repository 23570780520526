import React from 'react';

import axios from '../utils/axios';

const useAudioObjectURL = (remoteObjectUrl: string) => {
  const [objectURL, setObjectURL] = React.useState<string | null>(null);

  React.useEffect(() => {
    let url: string;
    axios
      .get(remoteObjectUrl, {
        responseType: 'arraybuffer',
      })
      .then(res => {
        url = URL.createObjectURL(new Blob([res.data], { type: 'audio/mp3' }));
        setObjectURL(url);
      });

    return () => URL.revokeObjectURL(url);
  }, [remoteObjectUrl]);

  return objectURL;
};

export default useAudioObjectURL;
