import React from 'react';
import { Button, H4, Navbar } from '@blueprintjs/core';
import { Link, useLocation } from 'react-router-dom';

import useAuth from '../../effects/useAuth';

import LoggedUser from './LoggedUser';

const NavBar = () => {
  const { isAuthenticated, username } = useAuth();
  const location = useLocation();

  return (
    <Navbar fixedToTop>
      <Navbar.Group align="left">
        <Navbar.Heading className="annotator-heading">
          <H4>Annotator</H4>
        </Navbar.Heading>
      </Navbar.Group>
      <Navbar.Group align="right">
        {isAuthenticated ? (
          <>
            {location.pathname === '/leaderboard' ? (
              <Link to="/">
                <Button minimal icon="arrow-left">
                  Go back
                </Button>
              </Link>
            ) : (
              <Link to="/leaderboard">
                <Button minimal icon="th">
                  Leaderboard
                </Button>
              </Link>
            )}
            <LoggedUser username={username} />
          </>
        ) : null}
      </Navbar.Group>
    </Navbar>
  );
};

export default NavBar;
