import React from 'react';
import { Button } from '@blueprintjs/core';

interface LoggedUserProps {
  username: string;
}

const LoggedUser = (props: LoggedUserProps) => {
  const { username } = props;

  return (
    <Button icon="thumbs-up" intent="success" disabled minimal>
      Logged-in as <b>{username}</b>
    </Button>
  );
};

export default LoggedUser;
