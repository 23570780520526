import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import './index.scss';

import App from './App';
import * as serviceWorker from './serviceWorker';
import AuthProvider from './components/AuthProvider';
import NavBar from './components/NavBar';

// TODO(vmatt): put providers in the App component, in order to test the
//  rendering of the full component tree.

ReactDOM.render(
  <BrowserRouter>
    <AuthProvider>
      <div className="annotator-root bp3-dark">
        <NavBar />
        <App />
      </div>
    </AuthProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
