import React from 'react';

interface AuthState {
  isAuthenticated: boolean;
  username: string;
  refresh: string;
  error: string;
  status: number | null;
}

const initialState: AuthState = {
  isAuthenticated: false,
  username: '',
  refresh: '',
  error: '',
  status: null,
};

const LOGIN = 'LOGIN';
interface Login {
  type: typeof LOGIN;
  username: string;
  refresh: string;
}
export const login = (data: { username: string; refresh: string }): Login => ({
  type: LOGIN,
  ...data,
});

const LOGOUT = 'LOGOUT';
interface Logout {
  type: typeof LOGOUT;
}

export const logout = (): Logout => ({ type: LOGOUT });

const ERROR = 'ERROR';
interface Error {
  type: typeof ERROR;
  error: string;
  status: number;
}
export const error = (data: { error: string; status: number }): Error => ({
  type: ERROR,
  ...data,
});

export type AuthAction = Login | Logout | Error;

const authReducer = (state: AuthState, action: AuthAction) => {
  switch (action.type) {
    case LOGIN:
      return {
        isAuthenticated: true,
        username: action.username,
        refresh: action.refresh,
        error: '',
        status: null,
      };
    case LOGOUT:
      return {
        isAuthenticated: false,
        username: '',
        refresh: '',
        error: '',
        status: null,
      };
    case ERROR:
      return {
        ...state,
        error: action.error,
        status: action.status,
      };
    default:
      return state;
  }
};

interface AuthProviderProps {
  children?: React.ReactNode;
}
export const AuthContext = React.createContext<
  [
    React.ReducerState<typeof authReducer>,
    React.Dispatch<React.ReducerAction<typeof authReducer>>,
  ]
>(null!);

const AuthProvider = (props: AuthProviderProps) => {
  const { children } = props;

  const [state, dispatch] = React.useReducer(authReducer, initialState);
  // TODO(vmatt): Use localStorage and refresh token

  return (
    <AuthContext.Provider value={[state, dispatch]}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
